export default function CourseDetails() {

    return (
        <>
            <div class="container-fluid py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-lg-8">
                            <nav aria-label="breadcrumb ">
                                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-3">
                                    <li className="breadcrumb-item"><a className=" text-secondary" href="/institution">Back to Course</a></li>

                                </ol>
                            </nav>
                            <div class="mb-5">

                                <div class="section-titles position-relative mb-5">
                                    <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">Course Detail</h6>
                                    <h1 class="display-4">Web design & development courses for beginners</h1>
                                </div>
                                <img class="img-fluid rounded w-100 mb-4" src="img/Institute/header.jpg" alt="Image" />
                                <p>Welcome to Bhattacharjee Group's Institute, where we're committed to shaping the future of the software industry by nurturing the next generation of tech leaders.

                                    In today's dynamic digital landscape, proficiency in modern technologies is paramount. Our institute offers comprehensive training programs designed to equip individuals with the skills and knowledge needed to excel in the software industry.

                                    From mastering the latest tech stacks for website development to delving into the intricacies of artificial intelligence and machine learning, our curriculum is tailored to meet the demands of the ever-evolving tech sector.

                                    Led by industry experts and seasoned professionals, our hands-on courses provide practical, real-world experience that prepares students to hit the ground running in their careers. Whether you're a seasoned professional looking to upskill or a newcomer eager to break into the field, our institute provides the perfect environment to thrive and succeed.

                                    Join us at Bhattacharjee Group's Institute and embark on a journey of learning, growth, and endless possibilities in the dynamic world of software technology.</p>

                                {/* <p>Welcome to Bhattacharjee Group's Institute, where innovation meets education to shape the future of the software industry. Our dynamic programs empower individuals with cutting-edge skills in web development, AI, and machine learning, ensuring they emerge as industry-ready professionals. Led by experts and fueled by hands-on learning, our institute is your gateway to a thriving career in the rapidly evolving world of technology. Join us and unleash your potential at Bhattacharjee Group's Institute.</p> */}
                            </div>

                            {/* <h2 class="mb-3">Related Courses</h2>
                    <div class="owl-carousel related-carousel position-relative" style="padding: 0 30px;">
                        <a class="courses-list-item position-relative d-block overflow-hidden mb-2" href="detail.html">
                            <img class="img-fluid" src="img/courses-1.jpg" alt=""/>
                            <div class="courses-text">
                                <h4 class="text-center text-white px-3">Web design & development courses for
                                    beginners</h4>
                                <div class="border-top w-100 mt-3">
                                    <div class="d-flex justify-content-between p-4">
                                        <span class="text-white"><i class="fa fa-user mr-2"></i>Jhon Doe</span>
                                        <span class="text-white"><i class="fa fa-star mr-2"></i>4.5
                                            <small>(250)</small></span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a class="courses-list-item position-relative d-block overflow-hidden mb-2" href="detail.html">
                            <img class="img-fluid" src="img/courses-2.jpg" alt=""/>
                            <div class="courses-text">
                                <h4 class="text-center text-white px-3">Web design & development courses for
                                    beginners</h4>
                                <div class="border-top w-100 mt-3">
                                    <div class="d-flex justify-content-between p-4">
                                        <span class="text-white"><i class="fa fa-user mr-2"></i>Jhon Doe</span>
                                        <span class="text-white"><i class="fa fa-star mr-2"></i>4.5
                                            <small>(250)</small></span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a class="courses-list-item position-relative d-block overflow-hidden mb-2" href="detail.html">
                            <img class="img-fluid" src="img/courses-3.jpg" alt=""/>
                            <div class="courses-text">
                                <h4 class="text-center text-white px-3">Web design & development courses for
                                    beginners</h4>
                                <div class="border-top w-100 mt-3">
                                    <div class="d-flex justify-content-between p-4">
                                        <span class="text-white"><i class="fa fa-user mr-2"></i>Jhon Doe</span>
                                        <span class="text-white"><i class="fa fa-star mr-2"></i>4.5
                                            <small>(250)</small></span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}
                        </div>

                        <div class="col-lg-4 mt-5 mt-lg-0">
                            <div class="bg-primary mb-5 py-3">
                                <h3 class="text-white py-3 px-4 m-0">Course Features</h3>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Instructor</h6>
                                    <h6 class="text-white my-3">John Doe</h6>
                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Rating</h6>
                                    <h6 class="text-white my-3">4.5 <small>(250)</small></h6>
                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Lectures</h6>
                                    <h6 class="text-white my-3">15</h6>
                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Duration</h6>
                                    <h6 class="text-white my-3">10.00 Hrs</h6>
                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Skill level</h6>
                                    <h6 class="text-white my-3">All Level</h6>
                                </div>
                                <div class="d-flex justify-content-between px-4">
                                    <h6 class="text-white my-3">Language</h6>
                                    <h6 class="text-white my-3">English</h6>
                                </div>
                                <h5 class="text-white py-3 px-4 m-0">Course Price: $199</h5>
                                <div class="py-3 px-4">
                                    <a class="btn btn-block btn-secondary py-3 px-5" href="">Enroll Now</a>
                                </div>
                            </div>

                            <div class="mb-5">
                                <h2 class="mb-3">Categories</h2>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a href="" class="text-decoration-none h6 m-0">Web Design</a>
                                        <span class="badge badge-primary badge-pill">150</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a href="" class="text-decoration-none h6 m-0">Web Development</a>
                                        <span class="badge badge-primary badge-pill">131</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a href="" class="text-decoration-none h6 m-0">Online Marketing</a>
                                        <span class="badge badge-primary badge-pill">78</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a href="" class="text-decoration-none h6 m-0">Keyword Research</a>
                                        <span class="badge badge-primary badge-pill">56</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a href="" class="text-decoration-none h6 m-0">Email Marketing</a>
                                        <span class="badge badge-primary badge-pill">98</span>
                                    </li>
                                </ul>
                            </div>

                            <div class="mb-5">
                                <h2 class="mb-4">Recent Courses</h2>
                                <a class="d-flex align-items-center text-decoration-none mb-4" href="">
                                    <img class="img-fluid course_detail_list rounded" src="img/Institute/courses-80x80.jpg" alt="" />
                                    <div class="pl-3">
                                        <h6>Web design & development courses for beginners</h6>
                                        <div class="d-flex">
                                            <small class="text-body mr-3"><i class="fa fa-user text-primary mr-2"></i>Jhon Doe</small>
                                            <small class="text-body"><i class="fa fa-star text-primary mr-2"></i>4.5 (250)</small>
                                        </div>
                                    </div>
                                </a>
                                <a class="d-flex align-items-center text-decoration-none mb-4" href="">
                                    <img class="img-fluid rounded course_detail_list" src="img/Institute/courses-80x80.jpg" alt="" />
                                    <div class="pl-3">
                                        <h6>Web design & development courses for beginners</h6>
                                        <div class="d-flex">
                                            <small class="text-body mr-3"><i class="fa fa-user text-primary mr-2"></i>Jhon Doe</small>
                                            <small class="text-body"><i class="fa fa-star text-primary mr-2"></i>4.5 (250)</small>
                                        </div>
                                    </div>
                                </a>
                                <a class="d-flex align-items-center text-decoration-none mb-4" href="">
                                    <img class="img-fluid rounded course_detail_list" src="img/Institute/courses-80x80.jpg" alt="" />
                                    <div class="pl-3">
                                        <h6>Web design & development courses for beginners</h6>
                                        <div class="d-flex">
                                            <small class="text-body mr-3"><i class="fa fa-user text-primary mr-2"></i>Jhon Doe</small>
                                            <small class="text-body"><i class="fa fa-star text-primary mr-2"></i>4.5 (250)</small>
                                        </div>
                                    </div>
                                </a>
                                <a class="d-flex align-items-center text-decoration-none" href="">
                                    <img class="img-fluid rounded course_detail_list" src="img/Institute/courses-80x80.jpg" alt="" />
                                    <div class="pl-3">
                                        <h6>Web design & development courses for beginners</h6>
                                        <div class="d-flex">
                                            <small class="text-body mr-3"><i class="fa fa-user text-primary mr-2"></i>Jhon Doe</small>
                                            <small class="text-body"><i class="fa fa-star text-primary mr-2"></i>4.5 (250)</small>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}