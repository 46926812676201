import About from "./About";
import Banner from "./Banner";

import ClientReview from "./Client_Review";
import Feature from "./Feature";
import Products from "./Products";
import Services from "./Services";
import Team from "./Team";
import { useEffect } from "react";
const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function Home(){
  useEffect(() => {
    scrollToTop();
}, []);
    return(
        <>
     
        <Banner/>
        {/* <About/> */}
        {/* <Services/> */}
       
        <Feature/>
        {/* <Products/> */}
        <Team/>
        {/* <ClientReview/> */}

        <div className="wow fadeIn" data-wow-delay="0.5s" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
          <iframe src="https://www.google.com/maps/d/embed?mid=1zFQgdjVjsLER9xwKzsMUUONb5eYEeJ0&ehbc=2E312F"
                     width="90%"
                     height="480"></iframe>
           
          </div>  
        
       
        
        
        </>
    )
}