import { Link } from "react-router-dom";

export default function BannerCard ({BannerDetails2}) {
    //const {title2} = title2;
    const {tag, title, description, image,readMore} = BannerDetails2;
    console.log("BannerDetails: "+BannerDetails2)

    return(
        <>
          <div className="container pt-5 item ">
            <div className="row g-5 pt-5">
                <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">{tag}</div>
                    <h1 className="display-4 text-white mb-4 animated slideInRight">{title}</h1>
                    <p className="text-white mb-4 animated slideInRight">{description}</p>
                    <Link to={readMore} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight">Read More</Link>
                    <Link to="/contact" className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</Link>
                </div>
                <div className="col-lg-6 align-self-end text-center text-lg-end">
                    <img className="img-fluids" src={image} alt=""/>
                </div>
            </div>
        </div>
        </>
    )
}