import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodepen} from '@fortawesome/free-brands-svg-icons';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};
function Cloud_Solution() {
    useEffect(() => {
        scrollToTop();
    }, []);
    const items = [
        {
              icon: faCodepen,
            title: 'Cloud Platforms',
            descr: ' ✦ IBM\n ✦ Google\n ✦ Azure\n ',

            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'AWS Services',
            descr: '★ EC2\n ★ S3\n ★ Lambda\n    ',
            color: '#6710F5',
        },
        {
              icon: faCodepen,
            title: 'Containerization',
            descr: '✦ Kubernetes\n  ✦ Docker\n ',
            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'MLOps Tools',
            descr: '★ Dataiku\n   ',
            color: '#6710F5',
        },

    ];
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">

                                <h1 className="mt-2">Cloud Solutions</h1>
                            </div>
                            <p className="mb-4">
                                At Robo Analytics, we excel in providing comprehensive cloud solutions tailored to meet the evolving needs of businesses across various industries. Leveraging top-tier cloud platforms such as IBM Cloud, Google Cloud AI, Azure, and AWS, including services like EC2, ECR, Route 53, Lambda, API Gateway, and S3, we ensure seamless scalability, reliability, and security for our clients' applications and data. Our expertise extends to containerization technologies like Kubernetes and Docker, enabling efficient deployment and management of cloud-native applications. With a focus on MLOps, we leverage tools like Dataiku to streamline machine learning workflows and optimize model deployment in the cloud environment. At Robo Analytics, we are committed to empowering businesses with robust and innovative cloud solutions that drive digital transformation and success.
                            </p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Cloud Platforms: IBM, Google, Azure</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Containerization: Kubernetes, Docker</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> Scalability & Security</h6>



                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>AWS Services: EC2, S3, Lambda</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> MLOps Tools: Dataiku</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>  Digital Transformation</h6>



                                </div>
                            </div>



                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/It/cloud_solution.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="ol_header">

                    <h1 className="mt-2 ">OUR EXPERTISE</h1>
                </div>
                <ol>
                    {items.map((item, index) => (
                        <li key={index} style={{ '--accent-color': item.color }}>
                            <div className="icon">
            <FontAwesomeIcon icon={item.icon} />
          </div>
                            <div className="title">{item.title}</div>
                            <div className="descr"> {item.descr.split('\n').map((line, i) => (
                                <div key={i}>{line}</div>
                            ))}</div>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    )
}

export default Cloud_Solution
