import { Link } from "react-router-dom";

import { useEffect } from "react";
const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function NavAbout(){
    useEffect(() => {
        scrollToTop();
    }, []);
    return(
        <>
     <div className=" bg-primary hero-header ">
                <div class="jumbotron_about jumbotron-fluid position-relative overlay-bottom" style={{ height: "655px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 pt-5">
                            {/* <h1 class="text-white mt-5 mb-4">Learn From Robo Analytics</h1>
            <h1 class="text-white display-1 mb-5">Education Courses</h1> */}
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div>
                                <h2 className=" text-white mt-1  mb-3 animated slideInRight">Pushing Your Brand to the Next Level</h2>
                                <h1 className="display-3 text-white mb-5 animated slideInRight">About Bhattacharjee Group</h1>

                            </div>

                        </div>
                    </div>

                </div>
           </div>
        
         {/* <div className="modal fade" id="searchModal" tabindex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(20, 24, 62, 0.7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn btn-square bg-white btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-light p-3"
                            placeholder="Type search keyword"/>
                        <button className="btn btn-light px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
         </div> */}

<div className="container-fluid py-5 " >
        <div className="container ">
            <div className="row g-5   align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className=" about-img">
                        <img className=" img-fluid" src="img/about-img.jpg"/>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn   " data-wow-delay="0.5s">
                    <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div>
                    <h1 className="mb-4">We Make Your Business Smarter with Artificial Intelligence</h1>
                    <p className="mb-4">Welcome to Bhattacharjee Group, a dynamic organization with over 12 years of experience spanning multiple domains. From pioneering AI and software development solutions to delivering top-notch construction projects, providing quality education through our institute, and offering unparalleled hospitality at our hotels, we are committed to excellence in every endeavor. Our dedication to innovation, quality, and customer satisfaction sets us apart, making Bhattacharjee Group your trusted partner for success.</p>
                    {/* <p className="mb-4">We specialize in crafting customized digital solutions, spanning AI, IoT, mobile apps, and cloud expertise, addressing the unique needs of businesses.</p> */}
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Award Winning</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                        </div>
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        {/* <Link className="btn btn-primary rounded-pill px-4 me-3" to="#">Read More</Link> */}
                        <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-instagram"></i></Link>
                        <Link className="btn btn-outline-primary btn-square" to="#"><i className="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
            </div>
        </div>
         </div>
    </>
    )
}