// import { Link } from "react-router-dom";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css'
// import { useEffect } from "react";

import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaPersonDigging } from "react-icons/fa6";

// const scrollToTop = () => {
//     window.scrollTo(0, 0);
// };

// export default function Hotel(){
//     useEffect(() => {
//         scrollToTop();
//     }, []);

//     const testimonial_carousel = ({
//         autoplay: true,
//         smartSpeed: 1000,
//         margin: 25,
//         dots: false,
//         loop: true,
//         nav : true,
//         navText : [
//             '<i className="bi bi-arrow-left"></i>',
//             '<i className="bi bi-arrow-right"></i>'
//         ],
//         responsive: {
//             0:{
//                 items:1
//             },
//             768:{
//                 items:2
//             }
//         }
//     });
    
//     return(
//         <>
//             <div className="container-fluid pt-5 position-relative   hero-header overlay-bottom"style={{ marginBottom: "50px", height: "752px" }}>
//       <div className="container pt-5 item">
//             <div className="row g-5 pt-5">
//                 <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
//                     <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div>
//                     <h1 className="display-4 text-white mb-4 animated slideInRight">Hotel</h1>
//                     <p className="text-white mb-4 animated slideInRight">'our hotel business, where we prioritize guest satisfaction above all else. Whether it's a luxurious retreat or a cozy boutique hotel, we strive to create memorable experiences that leave a lasting impression.'</p>
    
//                 </div>
//                 <div className="col-lg-6 align-self-end text-center text-lg-end">
//                     <img className="img-fluids" src="../img/baner4.png" alt=""/>
//                 </div>
//             </div>
//         </div>
//             </div>
//           {/* booking */}
//             <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
//             <div className="container">
//                 <div className="bg-white shadow" style={{padding: "35px"}}>
//                     <div className="row g-2">
//                         <div className="col-md-10">
//                             <div className="row g-2">
//                                 <div className="col-md-3">
//                                     <div className="date" id="date1" data-target-input="nearest">
//                                         <input type="date" className="form-control datetimepicker-input"
//                                             placeholder="Check in" data-target="#date1" data-toggle="datetimepicker" />
//                                     </div>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <div className="date" id="date2" data-target-input="nearest">
//                                         <input type="date" className="form-control datetimepicker-input" placeholder="Check out" data-target="#date2" data-toggle="datetimepicker"/>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <select className="form-select">
//                                         <option selected>Adult</option>
//                                         <option value="1">Adult 1</option>
//                                         <option value="2">Adult 2</option>
//                                         <option value="3">Adult 3</option>
//                                     </select>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <select className="form-select">
//                                         <option selected>Child</option>
//                                         <option value="1">Child 1</option>
//                                         <option value="2">Child 2</option>
//                                         <option value="3">Child 3</option>
//                                     </select>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-md-2">
//                             <button className="btn btn-primary w-100">Submit</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//           {/* about */}

//             <div className="container-xxl py-5">
//             <div className="container">
//                 <div className="row g-5 align-items-center">
//                     <div className="col-lg-6">
//                         {/* <h6 className="section-title-hotel text-start text-primary text-uppercase">About Us</h6> */}
//                         <h1 className="mb-4">Welcome to <span className="text-primary text-uppercase">BG Hotel</span></h1>
//                         <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p>
//                         <div className="row g-3 pb-4">
//                             <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
//                                 <div className="border rounded p-1">
//                                     <div className="border rounded text-center p-4">
//                                         <i className="fa fa-hotel fa-2x text-primary mb-2"></i>
//                                         <h2 className="mb-1" data-toggle="counter-up">1234</h2>
//                                         <p className="mb-0">Rooms</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
//                                 <div className="border rounded p-1">
//                                     <div className="border rounded text-center p-4">
//                                         <i className="fa fa-users-cog fa-2x text-primary mb-2"></i>
//                                         <h2 className="mb-1" data-toggle="counter-up">1234</h2>
//                                         <p className="mb-0">Staffs</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
//                                 <div className="border rounded p-1">
//                                     <div className="border rounded text-center p-4">
//                                         <i className="fa fa-users fa-2x text-primary mb-2"></i>
//                                         <h2 className="mb-1" data-toggle="counter-up">1234</h2>
//                                         <p className="mb-0">Clients</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <a className="btn btn-primary py-3 px-5 mt-2" href="">Explore More</a>
//                     </div>
//                     <div className="col-lg-6">
//                         <div className="row g-3">
//                             <div className="col-6 text-end">
//                                 <img className="img-fluid-hotel rounded w-75 wow zoomIn" data-wow-delay="0.1s" src="img/Hotel/about-1.png" style={{marginTop: "25%"}}/>
//                             </div>
//                             <div className="col-6 text-start">
//                                 <img className="img-fluid-hotel rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="img/Hotel/about-2.png"/>
//                             </div>
//                             <div className="col-6 text-end">
//                                 <img className="img-fluid-hotel rounded w-50 wow zoomIn" data-wow-delay="0.5s" src="img/Hotel/about-3.png"/>
//                             </div>
//                             <div className="col-6 text-start">
//                                 <img className="img-fluid-hotel rounded w-75 wow zoomIn" data-wow-delay="0.7s" src="img/Hotel/about-4.png"/>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//           </div>

//           {/* <!-- Room Start --> */}

//         <div className="container-xxl py-5">
//             <div className="container">
//                 <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
//                     {/* <h6 className="section-title-hotel text-center text-primary text-uppercase">Our Rooms</h6> */}
//                     <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Rooms</span></h1>
//                 </div>
//                 <div className="row g-4">
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
//                         <div className="room-item shadow rounded overflow-hidden">
//                             <div className="position-relative">
//                                 <img className="img-fluid" src="img/Hotel/room-1.jpg" alt=""/>
//                                 <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">$100/Night</small>
//                             </div>
//                             <div className="p-4 mt-2">
//                                 <div className="d-flex justify-content-between mb-3">
//                                     <h5 className="mb-0">Junior Suite</h5>
//                                     <div className="ps-2">
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                     </div>
//                                 </div>
//                                 <div className="d-flex mb-3">
//                                     <small className="border-end me-3 pe-3"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
//                                     <small className="border-end me-3 pe-3"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
//                                     <small><i className="fa fa-wifi text-primary me-2"></i>Wifi</small>
//                                 </div>
//                                 <p className="text-body mb-3">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                                 <div className="d-flex justify-content-between">
//                                     <a className="btn btn-sm btn-primary rounded py-2 px-4" href="">View Detail</a>
//                                     <a className="btn btn-sm btn-dark rounded py-2 px-4" href="">Book Now</a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
//                         <div className="room-item shadow rounded overflow-hidden">
//                             <div className="position-relative">
//                                 <img className="img-fluid" src="img/Hotel/room-2.jpg" alt=""/>
//                                 <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">$100/Night</small>
//                             </div>
//                             <div className="p-4 mt-2">
//                                 <div className="d-flex justify-content-between mb-3">
//                                     <h5 className="mb-0">Executive Suite</h5>
//                                     <div className="ps-2">
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                     </div>
//                                 </div>
//                                 <div className="d-flex mb-3">
//                                     <small className="border-end me-3 pe-3"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
//                                     <small className="border-end me-3 pe-3"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
//                                     <small><i className="fa fa-wifi text-primary me-2"></i>Wifi</small>
//                                 </div>
//                                 <p className="text-body mb-3">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                                 <div className="d-flex justify-content-between">
//                                     <a className="btn btn-sm btn-primary rounded py-2 px-4" href="">View Detail</a>
//                                     <a className="btn btn-sm btn-dark rounded py-2 px-4" href="">Book Now</a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
//                         <div className="room-item shadow rounded overflow-hidden">
//                             <div className="position-relative">
//                                 <img className="img-fluid" src="img/Hotel/room-3.jpg" alt=""/>
//                                 <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">$100/Night</small>
//                             </div>
//                             <div className="p-4 mt-2">
//                                 <div className="d-flex justify-content-between mb-3">
//                                     <h5 className="mb-0">Super Deluxe</h5>
//                                     <div className="ps-2">
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                         <small className="fa fa-star text-primary"></small>
//                                     </div>
//                                 </div>
//                                 <div className="d-flex mb-3">
//                                     <small className="border-end me-3 pe-3"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
//                                     <small className="border-end me-3 pe-3"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
//                                     <small><i className="fa fa-wifi text-primary me-2"></i>Wifi</small>
//                                 </div>
//                                 <p className="text-body mb-3">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                                 <div className="d-flex justify-content-between">
//                                     <a className="btn btn-sm btn-primary rounded py-2 px-4" href="">View Detail</a>
//                                     <a className="btn btn-sm btn-dark rounded py-2 px-4" href="">Book Now</a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         {/* <!-- Service Start --> */}

//         <div className="container-xxl py-5">
//             <div className="container">
//                 <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
//                     {/* <h6 className="section-title-hotel text-center text-primary text-uppercase">Our Services</h6> */}
//                     <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Services</span></h1>
//                 </div>
//                 <div className="row g-4">
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
//                         <a className="service-item-hotel rounded" href="">
//                             <div className="service-hotel-icon bg-transparent border rounded p-1">
//                                 <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
//                                     <i className="fa fa-hotel fa-2x text-primary"></i>
//                                 </div>
//                             </div>
//                             <h5 className="mb-3">Rooms & Appartment</h5>
//                             <p className="text-body mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                         </a>
//                     </div>
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
//                         <a className="service-item-hotel rounded" href="">
//                             <div className="service-hotel-icon bg-transparent border rounded p-1">
//                                 <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
//                                     <i className="fa fa-utensils fa-2x text-primary"></i>
//                                 </div>
//                             </div>
//                             <h5 className="mb-3">Food & Restaurant</h5>
//                             <p className="text-body mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                         </a>
//                     </div>
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
//                         <a className="service-item-hotel rounded" href="">
//                             <div className="service-hotel-icon bg-transparent border rounded p-1">
//                                 <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
//                                     <i className="fa fa-spa fa-2x text-primary"></i>
//                                 </div>
//                             </div>
//                             <h5 className="mb-3">Spa & Fitness</h5>
//                             <p className="text-body mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                         </a>
//                     </div>
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
//                         <a className="service-item-hotel rounded" href="">
//                             <div className="service-hotel-icon bg-transparent border rounded p-1">
//                                 <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
//                                     <i className="fa fa-swimmer fa-2x text-primary"></i>
//                                 </div>
//                             </div>
//                             <h5 className="mb-3">Sports & Gaming</h5>
//                             <p className="text-body mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                         </a>
//                     </div>
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
//                         <a className="service-item-hotel rounded" href="">
//                             <div className="service-hotel-icon bg-transparent border rounded p-1">
//                                 <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
//                                     <i className="fa fa-glass-cheers fa-2x text-primary"></i>
//                                 </div>
//                             </div>
//                             <h5 className="mb-3">Event & Party</h5>
//                             <p className="text-body mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                         </a>
//                     </div>
//                     <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
//                         <a className="service-item-hotel rounded" href="">
//                             <div className="service-hotel-icon bg-transparent border rounded p-1">
//                                 <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
//                                     <i className="fa fa-dumbbell fa-2x text-primary"></i>
//                                 </div>
//                             </div>
//                             <h5 className="mb-3">GYM & Yoga</h5>
//                             <p className="text-body mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
//                         </a>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         {/* <!-- Video Start --> */}

//         <div className="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
//             <div className="row g-0">
//                 <div className="col-md-6 bg-dark d-flex align-items-center">
//                     <div className="p-5">
//                         <h6 className="section-title-hotel text-start text-white text-uppercase mb-3">Luxury Living</h6>
//                         <h1 className="text-white mb-4">Discover A Brand Luxurious Hotel</h1>
//                         <p className="text-white mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p>
//                         <a href="" className="btn btn-primary py-md-3 px-md-5 me-3">Our Rooms</a>
//                         <a href="" className="btn btn-light py-md-3 px-md-5">Book A Room</a>
//                     </div>
//                 </div>
//                 <div className="col-md-6">
//                     <div className="video">
//                         <button type="button" className="btn-play" data-bs-toggle="modal" data-src="https://youtu.be/4K6Sh1tsAW4?si=pZXmI3VARFkXW2vH" data-bs-target="#videoModal">
//                             <span></span>
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         <div className="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog">
//                 <div className="modal-content rounded-0">
//                     <div className="modal-header">
//                         <h5 className="modal-title" id="exampleModalLabel">BG HOTEL</h5>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
                       
//                         <div className="ratio ratio-16x9">
//                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/4K6Sh1tsAW4?si=pZXmI3VARFkXW2vH" id="video" allowfullscreen allowscriptaccess="always"
//                                 allow="autoplay"></iframe> 
//                         {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/4K6Sh1tsAW4?si=pZXmI3VARFkXW2vH" title="BG HOTEL" frameborder="0"  allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         {/* <div className="wow fadeIn" data-wow-delay="0.5s" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
//           <iframe src="https://www.google.com/maps/d/embed?mid=1zFQgdjVjsLER9xwKzsMUUONb5eYEeJ0&ehbc=2E312F"
//                      width="100%"
//                      height="480"></iframe>
//           </div> */}

//           {/* <!-- Testimonial Start --> */}
// {/* 
//         <div className="container-xxl testimonial-hotel my-5 py-5 bg-dark wow zoomIn" data-wow-delay="0.1s">
//             <div className="container">
//                 <OwlCarousel className="owl-carousel testimonial-hotel-carousel py-5"{...testimonial_carousel}>
//                     <div className="testimonial-hotel-item position-relative bg-white rounded overflow-hidden">
//                         <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd et erat magna eos</p>
//                         <div className="d-flex align-items-center">
//                             <img className="img-fluid flex-shrink-0 rounded" src="img/Hotel/testimonial-1.jpg" style={{width: "100px", height: "100px"}}/>
//                             <div className="ps-3">
//                                 <h6 className="fw-bold mb-1">Client Name</h6>
//                                 <small>Profession</small>
//                             </div>
//                         </div>
//                         <i className="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
//                     </div>
//                     <div className="testimonial-hotel-item position-relative bg-white rounded overflow-hidden">
//                         <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd et erat magna eos</p>
//                         <div className="d-flex align-items-center">
//                             <img className="img-fluid flex-shrink-0 rounded" src="img//Hotel/testimonial-2.jpg"style={{width: "100px", height: "100px"}}/>
//                             <div className="ps-3">
//                                 <h6 className="fw-bold mb-1">Client Name</h6>
//                                 <small>Profession</small>
//                             </div>
//                         </div>
//                         <i className="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
//                     </div>
//                     <div className="testimonial-hotel-item position-relative bg-white rounded overflow-hidden">
//                         <p>Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd et erat magna eos</p>
//                         <div className="d-flex align-items-center">
//                             <img className="img-fluid flex-shrink-0 rounded" src="img//Hotel/testimonial-3.jpg" style={{width: "100px", height: "100px"}}/>
//                             <div className="ps-3">
//                                 <h6 className="fw-bold mb-1">Client Name</h6>
//                                 <small>Profession</small>
//                             </div>
//                         </div>
//                         <i className="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
//                     </div>
//                 </OwlCarousel>
//             </div>
//         </div> */}
        
//         {/* <!-- Team Start --> */}

//         {/* <div className="container-xxl py-5">
//             <div className="container">
//                 <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
//                     <h6 className="section-title-hotel text-center text-primary text-uppercase">Our Team</h6>
//                     <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Staffs</span></h1>
//                 </div>
//                 <div className="row g-4">
//                     <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
//                         <div className="rounded shadow overflow-hidden">
//                             <div className="position-relative">
//                                 <img className="img-fluid" src="img/Hotel/team-1.jpg" alt=""/>
//                                 <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
//                                 </div>
//                             </div>
//                             <div className="text-center p-4 mt-3">
//                                 <h5 className="fw-bold mb-0">Full Name</h5>
//                                 <small>Designation</small>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
//                         <div className="rounded shadow overflow-hidden">
//                             <div className="position-relative">
//                                 <img className="img-fluid" src="img/Hotel/team-2.jpg" alt=""/>
//                                 <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
//                                 </div>
//                             </div>
//                             <div className="text-center p-4 mt-3">
//                                 <h5 className="fw-bold mb-0">Full Name</h5>
//                                 <small>Designation</small>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
//                         <div className="rounded shadow overflow-hidden">
//                             <div className="position-relative">
//                                 <img className="img-fluid" src="img/Hotel/team-3.jpg" alt=""/>
//                                 <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
//                                 </div>
//                             </div>
//                             <div className="text-center p-4 mt-3">
//                                 <h5 className="fw-bold mb-0">Full Name</h5>
//                                 <small>Designation</small>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
//                         <div className="rounded shadow overflow-hidden">
//                             <div className="position-relative">
//                                 <img className="img-fluid" src="img/Hotel/team-4.jpg" alt=""/>
//                                 <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
//                                     <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
//                                 </div>
//                             </div>
//                             <div className="text-center p-4 mt-3">
//                                 <h5 className="fw-bold mb-0">Full Name</h5>
//                                 <small>Designation</small>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div> */}

//         <div className="container-xxl py-5">
//             <div className="container">
//                 <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
//                     {/* <h6 className="section-title-hotel text-center text-primary text-uppercase">Contact Us</h6> */}
//                     <h1 className="mb-5"><span className="text-primary text-uppercase">Contact</span> For Any Query</h1>
//                 </div>
//                 <div className="row g-4">
//                     <div className="col-12">
//                         <div className="row gy-4">
//                             <div className="col-md-4">
//                                 <h6 className="section-title-hotel  text-start text-primary text-uppercase">Booking</h6>
//                                 <p><i className="fa fa-envelope-open text-primary me-2"></i>bhattacharjeegroup@gmail.com</p>
//                             </div>
//                             <div className="col-md-4">
//                                 <h6 className="section-title-hotel  text-start text-primary text-uppercase">Contact No</h6>
//                                 <p><i className="fa fa-phone-alt text-primary me-2"></i>+91 6290 382 261</p>
//                             </div>
//                             <div className="col-md-4">
//                                 <h6 className="section-title-hotel  text-start text-primary text-uppercase">Technical</h6>
//                                 <p><i className="fa fa-envelope-open text-primary me-2"></i>tech@example.com</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
//                         <iframe className="position-relative rounded w-100 h-100"
//                             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14732.389228988559!2d88.4429931!3d22.612841200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f041e77c35b%3A0xcfa945e63f08fc75!2sRobo%20Analytics!5e0!3m2!1sen!2sin!4v1705593932787!5m2!1sen!2sin"
//                             tabindex="0"></iframe>
//                     </div>
//                     <div className="col-md-6">
//                         <div className="wow fadeInUp" data-wow-delay="0.2s">
//                             <form>
//                                 <div className="row g-3">
//                                     <div className="col-md-6">
//                                         <div className="form-floating">
//                                             <input type="text" className="form-control" id="name" placeholder="Your Name"/>
//                                             <label for="name">Your Name</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="form-floating">
//                                             <input type="email" className="form-control" id="email" placeholder="Your Email"/>
//                                             <label for="email">Your Email</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-12">
//                                         <div className="form-floating">
//                                             <input type="text" className="form-control" id="subject" placeholder="Subject"/>
//                                             <label for="subject">Subject</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-12">
//                                         <div className="form-floating">
//                                             <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: "150px"}}></textarea>
//                                             <label for="message">Message</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-12">
//                                         <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
//                                     </div>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         </>
        
//     )
// }

const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

export default function Hotel(){
        useEffect(() => {
            scrollToTop();
        }, []);

        return(
            <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container text-center py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                    <FaPersonDigging fontSize={50} color="#1363c6"/> 
                        
                        <h1 className=" mb-3">We are coming back soon</h1>
                        <p className="mb-4">This page is under maintenance! Maybe go to our home page</p>
                        <Link className="btn btn-primary rounded-pill py-3 px-5" to="/">Go Back To Home</Link>
                    </div>
                </div>
            </div>
        </div>
        )

    }

    

    