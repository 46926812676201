import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { useEffect } from "react";



const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function Construction() {
    useEffect(() => {
        scrollToTop();
    }, []);

    const services = [
        {
            id: 1,
            title: 'Building Construction',
            iconClass: 'fa fa-3x fa-building text-primary',
            videoSrc: '/img/Construction/video1.mp4',
            description: 'Duo dolore et diam sed ipsum stet amet duo diam...',
            modalId: 'video1Modal'
        },
        {
            id: 2,
            title: 'House Renovation',
            iconClass: 'fa fa-3x fa-home text-primary',
            videoSrc: '/img/Construction/video2.mp4',
            description: 'Duo dolore et diam sed ipsum stet amet duo diam...',
            modalId: 'video2Modal'
        },
        {
            id: 3,
            title: 'Interior Design',
            iconClass: 'fa fa-3x fa-palette text-primary',
            videoSrc: '/img/Construction/video3.mp4',
            description: 'Duo dolore et diam sed ipsum stet amet duo diam...',
            modalId: 'video3Modal'
        },
        {
            id: 4,
            title: 'Architecture Design',
            iconClass: 'fa fa-3x fa-drafting-compass text-primary',
            videoSrc: '/img/Construction/video4.mp4',
            description: 'Duo dolore et diam sed ipsum stet amet duo diam...',
            modalId: 'video4Modal'
        },
        {
            id: 5,
            title: 'Painting',
            iconClass: 'fa fa-3x fa-paint-brush text-primary',
            videoSrc: '/img/Construction/video5.mp4',
            description: 'Duo dolore et diam sed ipsum stet amet duo diam...',
            modalId: 'video5Modal'
        }
    ];

    const portfolioItems = [
        {
            title: 'MAA Tower',
            location: 'Shitala Tala, Hatiara Road, Kolkata',
            imageSrc: 'img/Construction/portfolio-1.jpg',
            lightboxSrc: 'img/Construction/portfolio-1.jpg',
            delay: '0.1s'
        },
        {
            title: 'Arun Villa',
            location: '26 Binodini Avenue, Hatiara Road, Kolkata',
            imageSrc: 'img/Construction/portfolio-2.jpg',
            lightboxSrc: 'img/Construction/portfolio-2.jpg',
            delay: '0.3s'
        },
        {
            title: 'Mamta Villa',
            location: 'Kestopur Vip Road, Kolkata',
            imageSrc: 'img/Construction/portfolio-3.jpg',
            lightboxSrc: 'img/Construction/portfolio-3.jpg',
            delay: '0.6s'
        },
        {
            title: 'Sonali Tower',
            location: 'Chinar Park, Newtown, Kolkata',
            imageSrc: 'img/Construction/portfolio-4.jpg',
            lightboxSrc: 'img/Construction/portfolio-4.jpg',
            delay: '0.1s'
        },
        {
            title: 'Upcoming Project',
            location: 'Near City Canter 2, Kolkata',
            imageSrc: 'img/Construction/portfolio-5.jpg',
            lightboxSrc: 'img/Construction/portfolio-5.jpg',
            delay: '0.3s'
        },
        {
            title: 'Upcoming Project',
            location: 'Hatiara, Kolkata',
            imageSrc: 'img/Construction/portfolio-6.jpg',
            lightboxSrc: 'img/Construction/portfolio-6.jpg',
            delay: '0.6s'
        }
    ];
    
    
    

    const testimonial_carousel = ({
        autoplay: true,
        smartSpeed: 1000,
        items: 1,
        dots: false,
        loop: true,
    });
    return (
        <>


            <div className="container-fluid pt-5 position-relative   hero-header overlay-bottom" style={{ marginBottom: "50px", height: "752px" }}>
                <div className="container   pt-5">
                    <div className="row g-5  ">
                        <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div>

                            <h1 className="display-4 text-white mb-4 animated slideInRight"> Ganapati Construction</h1>
                            <p className="text-white mb-4 animated slideInRight">'With a focus on quality craftsmanship and attention to detail, our construction division delivers projects that stand the test of time. From commercial buildings to residential complexes, we strive to exceed client expectations while adhering to the highest standards of safety and sustainability.'</p>
                        </div>
                        <div className="col-lg-6 align-self-end text-center text-lg-end">
                            <img className="img-fluids" src="../img/baner3.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* about */}

            {/* <div className="container-fluid mb-5 py-6 px-5">
        <div className="row g-5">
            <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="display-5 text-uppercase mb-4">We are <span className="text-primary">the Leader</span> in Construction Industry</h1>
                <h4 className="text-uppercase mb-3 text-body">Tempor erat elitr at rebum at at clita. Diam dolor diam ipsum tempor sit diam amet diam et eos labore</h4>
                <p>Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos sadipscing labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor</p>
                <div className="row gx-5 py-2">
                    <div className="col-sm-6 mb-2">
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Perfect Planning</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Professional Workers</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>First Working Process</p>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Perfect Planning</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Professional Workers</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>First Working Process</p>
                    </div>
                </div>
                <p className="mb-4">Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos labore</p>
                <img src="img/Construction/signature.jpg" alt=""/>
            </div>
            <div className="col-lg-5 pb-5 wow zoomIn" data-wow-delay="0.5s" style={{minHeight: "400px"}}>
                <div className="position-relative bg-dark-radial h-100 ms-5">
                    <img className="position-absolute w-100 h-100 mt-5 ms-n5" src="img/Construction/about.jpg" style={{objectFit: "cover"}}/>
                </div>
            </div>
        </div>
           </div> */}

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="row gx-3 h-100">
                                <div className="col-6 align-self-start wow fadeInUp" data-wow-delay="0.1s">
                                    <img className="img-fluid" src="img/Construction/about-1.jpg" />
                                </div>
                                <div className="col-6 align-self-end wow fadeInDown" data-wow-delay="0.1s">
                                    <img className="img-fluid" src="img/Construction/about-2.png" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <p className="fw-medium text-uppercase text-primary mb-2">About Us</p>
                            <h1 className="display-5 mb-4">We Are Leader In Industrial Market</h1>
                            <p className="mb-4">With a focus on quality craftsmanship and attention to detail, our construction division delivers projects that stand the test of time. </p>
                            <div className="d-flex align-items-center mb-4">
                                <div className="flex-shrink-0 bg-primary p-4">
                                    <h1 className="display-2">12</h1>
                                    <h5 className="text-white">Years of</h5>
                                    <h5 className="text-white">Experience</h5>
                                </div>
                                <div class="ms-4">
                                    <p><i className="fa fa-check text-primary me-2"></i>BUILDING CONSTRUCTION</p>
                                    <p><i className="fa fa-check text-primary me-2"></i>HOUSE RENOVATION</p>
                                    <p><i className="fa fa-check text-primary me-2"></i>ARCHITECTURE DESIGN</p>
                                    <p><i className="fa fa-check text-primary me-2"></i>FIXING & SUPPORT</p>
                                    <p className="mb-0"><i class="fa fa-check text-primary me-2"></i>INTERIOR DESIGN</p>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-sm-7">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                            <i className="fa fa-envelope-open text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <p className="mb-2">Email us</p>
                                            <h6 className="mb-0">bhattacharjeegroup@gmail.com</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                            <i className="fa fa-phone-alt text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <p className="mb-2 mr-3">Call us</p>
                                            <h6 className="mb-0">+91 6290 382 261</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* services */}
            <div className="container-fluid bg-light py-6 px-5" style={{ paddingTop: "90px", paddingBottom: "90px" }}>
            <div className="row g-5">
                {services.map(service => (
                    <div key={service.id} className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                        <div className="service-item-construction bg-white flex-column align-items-center text-center">
                            <div className={`video_construction_video${service.id}`}>
                                <button type="button" className="btn-play" data-bs-toggle="modal" data-bs-target={`#${service.modalId}`}>
                                    <span></span>
                                </button>
                            </div>
                            <div className="service-icon-construction bg-white">
                                <i className={service.iconClass}></i>
                            </div>
                            <div className="px-4 pb-4">
                                <h4 className="text-uppercase mb-3">{service.title}</h4>
                                <p>{service.description}</p>
                            </div>
                        </div>
                        <div className="modal fade" id={service.modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{service.title}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="ratio ratio-16x9">
                                            <video controls>
                                                <source src={service.videoSrc} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

            {/* <div className="container-fluid bg-light py-6 px-5 " style={{ paddingTop: "90px", paddingBottom: "90px" }}>
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{
                    maxWidth: "700px"
                }}>
                    <h1 className="display-5 text-uppercase mb-4">We Provide <span className="text-primary">The Best</span> Construction Services</h1>
                </div>
                
                <div className="row g-5">

                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                        <div className=" service-item-construction bg-white flex-column align-items-center text-center">
                            <div
                                className="video_construction_video1"
                            >
                                <button type="button" className="btn-play " data-bs-toggle="modal"data-src="/img/Construction/video1.mp4" data-bs-target="#video1Modal">
                                    <span></span>
                                </button>
                            </div>
                            <div className="service-icon-construction bg-white">
                                <i className="fa fa-3x fa-building  text-primary"></i>
                            </div>
                            <div className="px-4 pb-4">
                                <h4 className="text-uppercase mb-3">Building Construction</h4>
                                <p>Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo</p>
                        
                            </div>
                        </div>
                        <div className="modal fade" id="video1Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Building Construction</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="ratio ratio-16x9">
                                            <video id="video1" controls>
                                                <source src="/img/Construction/video1.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item-construction bg-white rounded  flex-column align-items-center text-center">
                            <div
                                className="video_construction_video2"
                            >
                                <button type="button" className="btn-play" data-bs-toggle="modal" data-bs-target="#video2Modal">
                                    <span></span>
                                </button>
                            </div>
                            <div className="service-icon-construction bg-white">
                                <i className="fa fa-3x fa-home text-primary"></i>
                            </div>
                            <div className="px-4 pb-4">
                                <h4 className="text-uppercase mb-3">House Renovation</h4>
                                <p>Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo</p>
                            </div>
                        </div>
                        <div className="modal fade" id="video2Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">House Renovation</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="ratio ratio-16x9">
                                            <video id="video2" controls>
                                                <source src="/img/Construction/video2.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div className="service-item-construction bg-white rounded  flex-column align-items-center text-center">
                            <div
                                className="video_construction_video3"
                            >
                                <button type="button" className="btn-play" data-bs-toggle="modal" data-bs-target="#video3Modal">
                                    <span></span>
                                </button>
                            </div>
                            <div className="service-icon-construction bg-white">
                                <i className="fa fa-3x fa-palette text-primary"></i>
                            </div>
                            <div className="px-4 pb-4">
                                <h4 className="text-uppercase mb-3">Interior Design</h4>
                                <p>Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo</p>
                            </div>
                        </div>
                        <div className="modal fade" id="video3Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">INTERIOR DESIGN</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="ratio ratio-16x9">
                                            <video id="video3" controls>
                                                <source src="/img/Construction/video3.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div className="service-item-construction bg-white rounded  flex-column align-items-center text-center">
                            <div
                                className="video_construction_video4"
                            >
                                <button type="button" className="btn-play" data-bs-toggle="modal" data-bs-target="#video4Modal">
                                    <span></span>
                                </button>
                            </div>
                            <div className="service-icon-construction bg-white">
                            <i className="fa fa-3x fa-drafting-compass text-primary"></i>
                            </div>
                            <div className="px-4 pb-4">
                                <h4 className="text-uppercase mb-3">Architecture Design</h4>
                                <p>Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo</p>
                            </div>
                        </div>
                        <div className="modal fade" id="video4Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Architecture Design</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="ratio ratio-16x9">
                                            <video id="video4" controls>
                                                <source src="/img/Construction/video4.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div className="service-item-construction bg-white rounded  flex-column align-items-center text-center">
                            <div
                                className="video_construction_video5"
                            >
                                <button type="button" className="btn-play" data-bs-toggle="modal" data-bs-target="#video5Modal">
                                    <span></span>
                                </button>
                            </div>
                            <div className="service-icon-construction bg-white">
                            <i className="fa fa-3x fa-paint-brush text-primary"></i>
                            </div>
                            <div className="px-4 pb-4">
                                <h4 className="text-uppercase mb-3">Painting</h4>
                                <p>Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo</p>
                            </div>
                        </div>
                        <div className="modal fade" id="video5Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Painting</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="ratio ratio-16x9">
                                            <video id="video5" controls>
                                                <source src="/img/Construction/video5.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

         
                </div>
            </div> */}

               {/* <Portfolio Start */}

               <div className="container-fluid bg-light py-6 px-5" style={{ paddingTop: "90px", paddingBottom: "90px" }}>
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                    <h1 className="display-5 text-uppercase mb-4">Some Of Our <span className="text-primary">Popular</span> Recent Projects</h1>
                </div>
                <div className="row gx-5">
                    <div className="col-12 text-center wow zoomIn" data-wow-delay="0.1s">
                        <div className="d-inline-block bg-dark-radial text-center pt-4 px-5 mb-5">
                            <ul className="list-inline mb-0" id="portfolio-flters">
                                <li className="btn btn-outline-primary bg-white p-2 active mx-2 mb-4" data-filter="*">
                                    <img src="img/Construction/portfolio-1.jpg" style={{ width: "150px", height: "100px" }} />
                                    <div className="position-absolute top-0 start-0 end-0 bottom-0 m-2 d-flex align-items-center justify-content-center" style={{ background: "rgba(4, 15, 40, .3)" }}>
                                        <h6 className="text-white text-uppercase m-0">All</h6>
                                    </div>
                                </li>
                                <li className="btn btn-outline-primary bg-white p-2 mx-2 mb-4" data-filter=".first">
                                    <img src="img/Construction/portfolio-2.jpg" style={{ width: "150px", height: "100px" }} />
                                    <div className="position-absolute top-0 start-0 end-0 bottom-0 m-2 d-flex align-items-center justify-content-center" style={{ background: "rgba(4, 15, 40, .3)" }}>
                                        <h6 className="text-white text-uppercase m-0">Construction</h6>
                                    </div>
                                </li>
                                <li className="btn btn-outline-primary bg-white p-2 mx-2 mb-4" data-filter=".second">
                                    <img src="img/Construction/portfolio-3.jpg" style={{ width: "150px", height: "100px" }} />
                                    <div className="position-absolute top-0 start-0 end-0 bottom-0 m-2 d-flex align-items-center justify-content-center" style={{ background: "rgba(4, 15, 40, .3)" }}>
                                        <h6 className="text-white text-uppercase m-0">Renovation</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                 <div className="row g-5 portfolio-construction-container">
            {portfolioItems.map((item, index) => (
                <div key={index} className={`col-xl-4 col-lg-6 col-md-6 portfolio-construction-item ${index % 2 === 0 ? 'first' : 'second'} wow zoomIn`} data-wow-delay={item.delay}>
                    <div className="position-relative portfolio-construction-box">
                        <img className="img-fluid w-100" src={item.imageSrc} alt="" />
                        <a className="portfolio-construction-title shadow-sm" href="#">
                            <p className="h4 text-uppercase">{item.title}</p>
                            <span className="text-body"><i className="fa fa-map-marker-alt text-primary me-2"></i>{item.location}</span>
                        </a>
                        <a className="portfolio-construction-btn" href={item.lightboxSrc} data-lightbox="portfolio-construction">
                            <i className="bi bi-plus text-white"></i>
                        </a>
                    </div>
                </div>
            ))}
        </div>
            </div>


            <div className="wow fadeIn" data-wow-delay="0.5s" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                <iframe src="https://www.google.com/maps/d/embed?mid=1zFQgdjVjsLER9xwKzsMUUONb5eYEeJ0&ehbc=2E312F"
                    width="95%"
                    height="480"></iframe>

            </div>

            {/* Appointment Start  */}
{/* 
            <div className="container-fluid  px-5" style={{ paddingTop: "90px", paddingBottom: "90px" }}>
                <div className="row gx-5">
                    <div className="col-lg-4  mb-5 mb-lg-0 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="mb-4">
                            <h1 className="display-5 text-uppercase mb-4">Request A <span className="text-primary">Call Back</span></h1>
                        </div>
                        <p className="mb-5">"Request a Call Back" is a convenient service offered in the construction industry, enabling customers to schedule a time for a phone conversation with a construction expert or representative. Whether it's to discuss project details, seek advice, or address concerns, this feature streamlines communication between clients and construction professionals. By simply filling out a form or providing contact details online, customers can expect a timely response tailored to their needs, ensuring efficient and personalized assistance throughout their construction journey.</p>
                      
                    </div>
                    <div className="col-lg-8 wow zoomIn" data-wow-delay="0.5s">
                        <div className="bg-light text-center p-5">
                            <form>
                                <div className="row g-3">
                                    <div className="col-12 col-sm-6">
                                        <input type="text" className="form-control border-0" placeholder="Your Name" style={{ height: "55px" }} />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <input type="email" className="form-control border-0" placeholder="Your Email" style={{ height: "55px" }} />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="date" id="date" data-target-input="nearest">
                                            <input type="text"
                                                className="form-control border-0 datetimepicker-input"
                                                placeholder="Call Back Date" data-target="#date" data-toggle="datetimepicker" style={{ height: "55px" }} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="time" id="time" data-target-input="nearest">
                                            <input type="text"
                                                className="form-control border-0 datetimepicker-input"
                                                placeholder="Call Back Time" data-target="#time" data-toggle="datetimepicker" style={{ height: "55px" }} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <textarea className="form-control border-0" rows="5" placeholder="Message"></textarea>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">Submit Request</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

         

            {/* team */}

            {/* <div className="container-fluid py-6 px-5"  style={{ paddingTop: "90px" ,paddingBottom: "90px"}}>
        <div className="text-center mx-auto mb-5  wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
            <h1 className="display-5 text-uppercase mb-4">We Are <span className="text-primary">Professional & Expert</span> Workers</h1>
        </div>
        <div className="row g-5">
            <div className="col-xl-3 col-lg-4 col-md-6  wow fadeInUp" data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: "300px"}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/team-1.jpg" style={{objectFit: "cover"}}/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            <a className="btn" href="#"><i className="fab fa-twitter"></i></a>
                            <a className="btn" href="#"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn" href="#"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn" href="#"><i className="fab fa-instagram"></i></a>
                            <a className="btn" href="#"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">Adam Phillips</h4>
                            <span>CEO & Founder</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6  wow fadeInUp" data-wow-delay="0.3s">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: "300px"}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/team-2.jpg"style={{objectFit: "cover"}}/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            <a className="btn" href="#"><i className="fab fa-twitter"></i></a>
                            <a className="btn" href="#"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn" href="#"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn" href="#"><i className="fab fa-instagram"></i></a>
                            <a className="btn" href="#"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">Dylan Adams</h4>
                            <span>Civil Engineer</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6  wow fadeInUp" data-wow-delay="0.6s">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: "300px"}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/team-3.jpg"style={{objectFit: "cover"}}/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            <a className="btn" href="#"><i className="fab fa-twitter"></i></a>
                            <a className="btn" href="#"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn" href="#"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn" href="#"><i className="fab fa-instagram"></i></a>
                            <a className="btn" href="#"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">Jhon Doe</h4>
                            <span>Interior Designer</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6  wow fadeInUp" data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: "300px"}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/team-4.jpg" style={{objectFit: "cover"}}/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            <a className="btn" href="#"><i className="fab fa-twitter"></i></a>
                            <a className="btn" href="#"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn" href="#"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn" href="#"><i className="fab fa-instagram"></i></a>
                            <a className="btn" href="#"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">Josh Dunn</h4>
                            <span>Painter</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}

            {/* <!-- Testimonial Start --> */}

            {/* <div className="container-fluid bg-light py-6 px-5" style={{ paddingTop: "90px", paddingBottom: "90px" }}>
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                    <h1 className="display-5 text-uppercase mb-4">What Our <span className="text-primary">Happy Cleints</span> Say!!!</h1>
                </div>
                <div className="row gx-0 align-items-center">
                    <div className="col-xl-4 col-lg-5 d-none d-lg-block">
                        <img className="img-fluid w-100 h-100" src="img/Construction/testimonial.jpg" />
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                        <div className="testimonial bg-light">
                            <OwlCarousel className="owl-carousel testimonial-carousel "{...testimonial_carousel}>
                                <div className="row gx-4 align-items-center">
                                    <div className="col-xl-4 col-lg-5 col-md-5">
                                        <img className="img-fluid w-100 h-100 bg-light p-lg-3 mb-4 mb-md-0" src="img/Construction/testimonial-1.jpg" alt="" />
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-7">
                                        <h4 className="text-uppercase mb-0">Sam</h4>
                                        <p>Profession</p>
                                        <p className="fs-5 mb-0"><i className="fa fa-2x fa-quote-left text-primary me-2"></i>
                                            Efficient and timely construction, with attention to detail and quality craftsmanship, ensuring structural integrity and client satisfaction.</p>
                                    </div>
                                </div>
                                <div className="row gx-4 align-items-center">
                                    <div className="col-xl-4 col-lg-5 col-md-5">
                                        <img className="img-fluid w-100 h-100 bg-light p-lg-3 mb-4 mb-md-0" src="img/Construction/testimonial-2.jpg" alt="" />
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-7">
                                        <h4 className="text-uppercase mb-0">Siree</h4>
                                        <p>Profession</p>
                                        <p className="fs-5 mb-0"><i className="fa fa-2x fa-quote-left text-primary me-2"></i>Exceptional construction services delivered with professionalism, reliability, and a commitment to excellence, exceeding expectations at every stage of the project</p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* our blog */}

            {/* <div className="container-fluid py-6 px-5" style={{ paddingTop: "90px", paddingBottom: "90px" }}>
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                    <h1 className="display-5 text-uppercase mb-4">Latest <span className="text-primary">Articles</span> From Our Blog Post</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="bg-light">
                            <img className="img-fluid" src="img/Construction/blog-1.jpg" alt="" />
                            <div className="p-4">
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <img className="rounded-circle me-2" src="img/Construction/user.jpg" width="35" height="35" alt="" />
                                        <span>John Doe</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="ms-3"><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2024</span>
                                    </div>
                                </div>
                                <h4 className="text-uppercase mb-3">BG Happyville</h4>
                                <a className="text-uppercase fw-bold" href="">Read More <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="bg-light">
                            <img className="img-fluid" src="img/Construction/blog-2.jpg" alt="" />
                            <div className="p-4">
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <img className="rounded-circle me-2" src="img/Construction/user.jpg" width="35" height="35" alt="" />
                                        <span>John Doe</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="ms-3"><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2025</span>
                                    </div>
                                </div>
                                <h4 className="text-uppercase mb-3"> BG Eden LakeVille</h4>
                                <a className="text-uppercase fw-bold" href="">Read More <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="bg-light">
                            <img className="img-fluid" src="img/Construction/blog-3.jpg" alt="" />
                            <div className="p-4">
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <img className="rounded-circle me-2" src="img/Construction/user.jpg" width="35" height="35" alt="" />
                                        <span>John Doe</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="ms-3"><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2020</span>
                                    </div>
                                </div>
                                <h4 className="text-uppercase mb-3">BG Galaxia</h4>
                                <a className="text-uppercase fw-bold" href="">Read More <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}