import { Link } from "react-router-dom";

export default function Products(){
    return(
        <> 
        <div className="container-fluid bg-light py-5">
          <div className="container py-5">
            <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Case Study</div>
                <h1 className="mb-4">Explore Our Recent Products Case Studies</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/project-1.jpg" alt=""/>
                        <Link className="case-overlay text-decoration-none" to="">
                            <small>IT</small>
                            <h5 className="lh-base text-white mb-3">Build Link strong online presence with our bespoke websites and designed.
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </Link>
                </div>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/project-2.jpg" alt=""/>
                        <Link className="case-overlay text-decoration-none" to="">
                            <small>Construction</small>
                            <h5 className="lh-base text-white mb-3"> the power of AI for business efficiency and innovation 
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                    <div className="case-item position-relative overflow-hidden rounded mb-2">
                        <img className="img-fluid" src="img/project-3.jpg" alt=""/>
                        <Link className="case-overlay text-decoration-none" to="">
                            <small>Institution</small>
                            <h5 className="lh-base text-white mb-3">Mobile App (Android & iOS) Development
                            </h5>
                            <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                        </Link>
                    </div>
                </div>
                
            </div>
        </div>
        </div>
    </>
    )
}