import React, { useEffect } from 'react'
const scrollToTop = () => {
    window.scrollTo(0, 0);
};
function Mobile_App() {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">

                                <h1 className="mt-2">App Development</h1>
                            </div>
                            <p className="mb-4">
                                Hiring a full-stack product team is not an economically viable solution for most businesses. However, that is exactly what you need if you want to provide a great mobile experience to your customers. By hiring our full-stack app development team, you will enjoy the benefits of having an in-house team without the trouble of maintaining one on your own. We provide efficient solutions like native apps, web apps, and hybrid apps to help you generate more revenue from your business.
                                .</p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> React Native</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Java</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Swift & Kotlin</h6>

                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Flutter Expertise</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Agile Methodologies</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i> CI/CD Pipelines</h6>

                                </div>
                            </div>



                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/It/mobile_app.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mobile_App
