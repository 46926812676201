
import { Link } from "react-router-dom";
import React, { useRef, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './Institute.css'
const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function Institution() {
    useEffect(() => {
        scrollToTop();
    }, []);

    const options = {
        autoplay: false,
        smartSpeed: 1500,
        loop: false,
        dots: false,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4
            }
        }
    };

    const team_carousel = ({
        autoplay: true,
        smartSpeed: 1000,
        margin: 30,
        dots: true,
        center: true,
        loop: true,
        nav: false,
        navText: [
            '<i class="bi bi-arrow-left"></i>',
            '<i class="bi bi-arrow-right"></i>'
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            }
        }
    });

    const testimonial_carousel = ({
        autoplay: true,
        smartSpeed: 1500,
        items: 1,
        dots: false,
        loop: true,



    });

    const courses = [
        {
            img: "img/Institute/courses-1.jpg",
            title: "AI Machine Learning",
            instructor: "aariv",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },
        {
            img: "img/Institute/courses-2.jpg",
            title: "Python Development",
            instructor: "Ravi",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },
        {
            img: "img/Institute/courses-3.jpg",
            title: "UI/UX design",
            instructor: "Jhon Doe",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },
        {
            img: "img/Institute/courses-4.jpg",
            title: "Digital Marketing",
            instructor: "Anshuman",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },
      
    ];

    const testimonialData = [
        {
            text: "I enrolled at Data Science course in ABITE Institute. I am highly recommended that it is much better than any another institute, the faculty member are so helpful and very much knowledgeable in subject and the faculty member always help to understand the basic concept as well as the detailed concept . I am very happy to a part of the ABITE Institute.",
            author: "Harry",
            course: "Data Science",
            imageSrc: "img/testimonial-2.jpg"
        },
        {
            text: "Training was excellent with good interaction. Knowledge sharing is good. The recording facility is excellent for revising. The course was practically and informative. Faculties are enthusiastic and really aware of what they are explaining.I would highly recommend ABITE Institute for learning and enhancing IT skills.",
            author: "Amrita",
            course: "Web Design",
            imageSrc: "img/testimonial-1.jpg"
        }
    ];
    


    return (
        <>
            <div className=" bg-primary hero-header ">
                <div className="jumbotron jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "50px", height: "752px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 pt-5">
                            {/* <h1 className="text-white mt-5 mb-4">Learn From Robo Analytics</h1>
            <h1 className="text-white display-1 mb-5">Education Courses</h1> */}
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">ABITE GROUP</div>
                                <h2 className=" text-white mt-1  mb-3 animated slideInRight">Learn From ABITE Institute</h2>
                                <h1 className="display-1 text-white mb-5 animated slideInRight">Education Courses</h1>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid px-0 py-5  ">
                <div className="row mx-0 justify-content-center pt-5">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.2s">
                        <div className="section-titles  text-center position-relative mb-4">
                            <h6 className="d-inline-block position-relative text-danger  text-uppercase pb-2">Our Courses</h6>
                            <h1 className="display-4">Checkout New Releases Of Our Courses</h1>
                        </div>
                    </div>
                </div>


                <OwlCarousel className="owl-carousel owl-theme courses-carousel wow fadeIn" data-wow-delay="0.5s" {...options}>
            {courses.map((course, index) => (
                <div className="courses-item position-relative" key={index}>
                    <img className="img-fluid" src={course.img} alt={course.title} />
                    <div className="courses-text">
                        <h4 className="text-center text-white px-3">{course.title}</h4>
                        <div className="border-top w-100 mt-3">
                            <div className="d-flex justify-content-between p-4">
                                <span className="text-white"><i className="fa fa-user mr-2"></i> {course.instructor}</span>
                                <span className="text-white"><i className="fa fa-star mr-2"></i>{course.rating} <small>({course.reviews})</small></span>
                            </div>
                        </div>
                        <div className="w-100 bg-white text-center p-4">
                            <a className="btn btn-primary" href={course.link}>Course Detail</a>
                        </div>
                    </div>
                </div>
            ))}
        </OwlCarousel>
            </div>

            {/* <div className="row justify-content-center bg-image mx-0 mb-5 wow fadeIn" data-wow-delay="0.5s">
                <div className="col-lg-6 py-5">
                    <div className="bg-white p-5 my-5">
                        <h1 className="text-center mb-4">30% Off For New Students</h1>
                        <form>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control bg-light border-0" placeholder="Your Name" style={{ padding: "25px 20px" }} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control bg-light border-0" placeholder="Your Email" style={{ padding: "25px 20px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <select className="custom-select bg-light border-0 px-3" style={{ height: "60px", width: "100%" }}>
                                            <option selected>Select A courses</option>
                                            <option value="1">courses 1</option>
                                            <option value="2">courses 1</option>
                                            <option value="3">courses 1</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <button className="btn btn-primary btn-block" type="submit" style={{ height: "60px", width: "100%" }}>Sign Up Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid py-5 ">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-5 mb-5 mb-lg-0 wow fadeIn" data-wow-delay="0.2s" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" src="../img/Institute/about.jpg" style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
                            <div className=" section-titles position-relative mb-4">
                                <h6 className="d-inline-block text-danger position-relative text-uppercase pb-2">About Us</h6>
                                <h1 className="display-6">Take The Next Step Toward Your Personal And Professional Goals With ABITE Institute</h1>
                            </div>
                            <p>ABITE Institute stands out as a premier Digital Marketing and Software Training institute in Kolkata.Renowned for its commitment to delivering top-notch education and ensuring a 100% placement assistance. With a focus on imparting quality training, the academy equips students with the latest skills and knowledge in Full Stack Web Development and Digital Marketing Course, fostering their professional growth. Students benefit from an industry-oriented curriculum, experienced faculty, and a dedicated placement support system, making ABITE Institute a trusted choice for those seeking a successful career in web design and Graphic Design.</p>
                            <div className="row pt-3 mx-0">
                                <div className="col-3 px-0">
                                    <div className="bg-success text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">123</h1>
                                        <h6 className="text-uppercase text-white">Available<span className="d-block">Subjects</span></h6>
                                    </div>
                                </div>
                                <div className="col-3 px-0">
                                    <div className="bg-primary text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">1234</h1>
                                        <h6 className="text-uppercase text-white">Online<span className="d-block">Courses</span></h6>
                                    </div>
                                </div>
                                <div className="col-3 px-0">
                                    <div className="bg-secondary text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">123</h1>
                                        <h6 className="text-uppercase text-white">Skilled<span className="d-block">Instructors</span></h6>
                                    </div>
                                </div>
                                <div className="col-3 px-0">
                                    <div className="bg-warning text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">1234</h1>
                                        <h6 className="text-uppercase text-white">Happy<span className="d-block">Students</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid px-0 py-5">


                <div className="container-fluid bg-image " style={{ margin: "90px 0" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 my-5 pt-5 pb-lg-5 wow fadeIn" data-wow-delay="0.5s">
                                <div className="section-titles position-relative mb-4">
                                    <h6 className="d-inline-block text-danger position-relative text-secondary text-uppercase pb-2">Why Choose Us?</h6>
                                    <h1 className="display-5">Why You Should Start Learning with Us?</h1>
                                </div>
                                <p className="mb-4 pb-2">With the changing trends in the IT industry, we always update our courses.
                                    ABITE Institute has been a premier digital marketing and web development course provider in Kolkata,
                                    with placement assistance.Whether you want to learn Data Science Course, MERN Stack Course, Graphic Design Course or any other,
                                    you will find us the best.</p>
                                <div className="d-flex mb-2">
                                    <div className="btn-icon bg-primary mr-4">
                                        {/* <i className="fa fa-2x fa-graduation-cap text-white"></i> */}
                                        <i className="fa fa-2x fa-edit text-white"></i>

                                    </div>
                                    <div className="mt-n1">
                                        <h4>Latest & Updated Courses </h4>
                                        <p>We always provide the latest and updated courses as per industry requirements. Our aim is to make you a professional.</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="btn-icon bg-secondary mr-4">
                                        <i className="fa fa-2x fa-certificate text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Globally Recognized Certificate</h4>
                                        <p>The certificate provided by us will be valid in any top company in the world as well as any recognized organizations.</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div className="btn-icon bg-warning mr-4">
                                        <i className="fa fa-2x fa-book-reader text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Online & Offline Classes</h4>
                                        <p>We provide both online and offline classes. You can choose as per your convenience. You can switch the mode at any time.</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="btn-icon bg-primary mr-4">
                                        <i className="fa fa-2x fa-user-graduate text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Small Size Batches</h4>
                                        <p >We mostly provide one-on-one and small batch training so that the quality of the training can be maintained.</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="btn-icon bg-danger mr-4">
                                        <i className="fa fa-2x fa-cogs text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Project Are Compulsory</h4>
                                        <p >In our courses, completing the projects is not optional but compulsory. To boost confidence in you, it's necessary.</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="btn-icon bg-success mr-4">
                                        <i className="fa fa-2x fa-map-marker text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>100% Placement Assistance.</h4>
                                        <p >We provide 100% placement assistance after successfully completing the course. We will not leave you until we place you.</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="btn-icon bg-warning mr-4">
                                        <i className="fa fa-2x fa-id-card text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Help In Resume & Interview.</h4>
                                        <p >We help you in interview preparation and resume building. We provide you with mock interview questions and answers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.2s" style={{ minHeight: "500px" }}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute w-100 h-100" src="img/Institute/feature.jpg" style={{ objectFit: "cover" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
                    <div class="row g-0">
                        <div class="col-md-6 bg-dark d-flex align-items-center">
                            <div class="p-5">
                                <h6 class="section-title-hotel text-start text-white text-uppercase mb-3">Best Learning</h6>
                                <h2 class="text-white mb-4">Learn Our  Enabled Couses
                                    ABITE Institute has included
                                    AI in all our courses to make you the
                                    pioneer in the IT Industry</h2>
                                <p class="text-white mb-4">Welcome to Bhattacharjee Group's Institute, where innovation fuels education for the future of software. Our dynamic programs empower with cutting-edge skills in web development, AI, and machine learning, ensuring industry readiness. Led by experts, join us to unleash your potential.</p>
                               
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="video-institute ">
                                <button type="button" class="btn-play" data-bs-toggle="modal" data-src="https://youtu.be/Kz3W_H3H92A?si=sShK0YJIb5Eg0njZ" data-bs-target="#videoModal">
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content rounded-0">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">ABITE Institute</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <div class="ratio ratio-16x9">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Kz3W_H3H92A?si=sShK0YJIb5Eg0njZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>



            {/* <div className="container-fluid py-5 ">
                <div className="container py-5">
                    <div className="section-titles text-center position-relative mb-5 wow fadeIn" data-wow-delay="0.2s">
                        <h6 className="d-inline-block position-relative text-danger text-uppercase pb-2">Instructors</h6>
                        <h1 className="display-4">Meet Our Instructors</h1>
                    </div>
                    <OwlCarousel className="owl-carousel owl-theme team-carousel-institute position-relative wow fadeIn" data-wow-delay="0.5s" {...team_carousel} style={{ padding: "0 30px" }}>

                        <div className="team-item">
                            <img className="img-fluid w-100" src="img/Institute/team-1.jpg" alt="" />
                            <div className="bg-light text-center p-4">
                                <h5 className="mb-3">Instructor Name</h5>
                                <p className="mb-2">Web Design & Development</p>
                                <div className="d-flex justify-content-center">
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-twitter"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-instagram"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="team-item">

                            <img className="img-fluid w-100" src="img/Institute/team-2.jpg" alt="" />
                            <div className="bg-light text-center p-4">
                                <h5 className="mb-3">Instructor Name</h5>
                                <p className="mb-2">Web Design & Development</p>
                                <div className="d-flex justify-content-center">
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-twitter"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-instagram"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="team-item">
                            <img className="img-fluid w-100" src="img/Institute/team-3.jpg" alt="" />
                            <div className="bg-light text-center p-4">
                                <h5 className="mb-3">Instructor Name</h5>
                                <p className="mb-2">Web Design & Development</p>
                                <div className="d-flex justify-content-center">
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-twitter"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-instagram"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="team-item">

                            <img className="img-fluid w-100" src="img/Institute/team-4.jpg" alt="" />
                            <div className="bg-light text-center p-4">
                                <h5 className="mb-3">Instructor Name</h5>
                                <p className="mb-2">Web Design & Development</p>
                                <div className="d-flex justify-content-center">
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-twitter"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-instagram"></i></a>
                                    <a className="mx-1 p-1" href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div> */}

            {/* team test */}

            {/* <div className="container-fluid bg-image py-5 " style={{ margin: "90px 0" }}>
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-5 mb-5 mb-lg-0 wow fadeIn" data-wow-delay="0.2s">
                            <div className="section-titles position-relative mb-4">
                                <h6 className="d-inline-block position-relative text-danger text-uppercase pb-2">Testimonial</h6>
                                <h1 className="display-4">What Say Our Students</h1>
                            </div>
                            <p className="m-0">Through a combination of lectures, readings, discussions, students will gain a solid
                                foundation in educational psychology.

                            </p>
                        </div>
                        <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
            <OwlCarousel className="owl-carousel testimonial-carousel" {...testimonial_carousel}>
                {testimonialData.map((testimonial, index) => (
                    <div key={index} className="bg-white p-5">
                        <p>{testimonial.text}</p>
                        <div className="d-flex flex-shrink-0 align-items-center mt-4">
                            <img className="img-fluid mr-4" src={testimonial.imageSrc} style={{ width: "80px" }} alt="" />
                            <div>
                                <h5>{testimonial.author}</h5>
                                <span>{testimonial.course}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid py-5 ">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-5 mb-5 mb-lg-0 wow fadeIn" data-wow-delay="0.2s">
                            <div className="bg-light d-flex flex-column justify-content-center px-5" style={{ height: "450px" }}>
                                <div className="d-flex align-items-center mb-5">
                                    <div className="btn-icon bg-primary mr-4">
                                        <i className="fa fa-2x fa-map-marker-alt text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Our Location</h4>
                                        <p className="m-0">26 No. Binodini Avenue, Hatiara Dhankol Road, Near Binodini Avenue park Kolkata - 700157</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-5">
                                    <div className="btn-icon bg-secondary mr-4">
                                        <i className="fa fa-2x fa-phone-alt text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Call Us</h4>
                                        <p className="m-0">+91 6290 382 261</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="btn-icon bg-warning mr-4">
                                        <i className="fa fa-2x fa-envelope text-white"></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>Email Us</h4>
                                        <p className="m-0">hr.roboanalytics@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
                            <div className="section-titles position-relative mb-4">
                                <h6 className="d-inline-block position-relative text-danger text-uppercase pb-2">Need Help?</h6>
                                <h1 className="display-4">Send Us A Message</h1>
                            </div>
                            <div className="contact-form">
                                <form>
                                    <div className="row">
                                        <div className="col-6 form-group">
                                            <input type="text" className="form-control border-top-0 border-right-0 border-left-0" placeholder="Your Name" required="required" />
                                        </div>
                                        <div className="col-6 form-group">
                                            <input type="email" className="form-control border-top-0 border-right-0 border-left-0 " placeholder="Your Email" required="required" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control border-top-0 border-right-0 border-left-0 " placeholder="Subject" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control border-top-0 border-right-0 border-left-0 " rows="5" placeholder="Message" required="required"></textarea>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary py-3 px-5" type="submit">Send Message</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="wow fadeIn" data-wow-delay="0.5s" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                    <iframe src="https://www.google.com/maps/d/embed?mid=1zFQgdjVjsLER9xwKzsMUUONb5eYEeJ0&ehbc=2E312F"
                        width="90%"
                        height="480"></iframe>
                </div>

                {/* <div id="mapContainer2" class="wow fadeIn" data-wow-delay="0.5s">
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d49553.90754149219!2d88.44732009560332!3d22.61213505353573!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275b8db540fd3%3A0x8e096edfe32c2ff3!2sKestopur%20VIP%20Bus%20Stand!5e0!3m2!1sen!2sin!4v1711712512233!5m2!1sen!2sin" 
                    width="100%" 
                    height="100%"
                    title="Company Location 2" />
                    <iframe
                        title="Company Location 1"
                        width="100%"
                        height="100%"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14732.389228988559!2d88.4429931!3d22.612841200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f041e77c35b%3A0xcfa945e63f08fc75!2sRobo%20Analytics!5e0!3m2!1sen!2sin!4v1705593932787!5m2!1sen!2sin"
                    />
                    
                </div> */}




            </div>


        </>

    )
}