import { Link } from "react-router-dom";

export default function Team() {
    return (
        <>
            <div className="container-fluid bg-light py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Founder & CEO</div> */}
                            <h1 className="mb-4">Meet Our Founder & CEO</h1>
                            <p className="mb-4">Meet our Founder & CEO, who brings over 13 years of invaluable expertise to our organization. With a profound understanding of the industry cultivated over more than a decade, our leader has navigated the complexities of this field with unparalleled vision and determination. Their strategic insights and leadership have propelled our company to new heights, shaping our journey towards continued success.</p>
                            {/* <Link className="btn btn-primary rounded-pill px-4" to="">Read More</Link> */}
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-6">
                                <div className="col-md-10">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                            <div className="team-item bg-white text-center rounded p-4 pt-0">
                                                <img className="img-fluid rounded-circle p-4" src="img/team-1.png" alt="" />
                                                <h5 className="mb-0">Abhishek Bhattacharjee</h5>
                                                <small>Founder & CEO</small>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-facebook-f"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-twitter"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-instagram"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-linkedin-in"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/team-2.jpg" alt=""/>
                                        <h5 className="mb-0">Adam Crew</h5>
                                        <small>Executive Manager</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-facebook-f"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-twitter"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-instagram"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-linkedin-in"></i></Link>
                                        </div>
                                    </div>
                                </div> */}
                                    </div>
                                </div>
                                {/* <div className="col-md-6 pt-md-4">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/team-3.jpg" alt=""/>
                                        <h5 className="mb-0">Kate Winslet</h5>
                                        <small>Co Founder</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-facebook-f"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-twitter"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-instagram"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-linkedin-in"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/team-4.jpg" alt=""/>
                                        <h5 className="mb-0">Cody Gardner</h5>
                                        <small>Project Manager</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-facebook-f"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-twitter"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-instagram"></i></Link>
                                            <Link className="btn btn-square btn-primary m-1" to=""><i
                                                    className="fab fa-linkedin-in"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}