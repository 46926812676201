import { Link } from "react-router-dom";

export default function Services() {
    return (
        <>
            <div className="container-fluid bg-light  py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        {/* <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Ventures</div>
                            <h1 className="mb-4">Our Ventures</h1>
                            <p className="mb-4">
"BG Group is a dynamic company dedicated to exploring innovative opportunities across various industries. With a forward-thinking approach and a commitment to excellence, we strive to identify and capitalize on emerging trends, fostering growth and driving positive change. BG Group is poised to lead the way in shaping the future landscape of business."</p>
                            <Link to="/services" className="btn px-3 mt-auto mx-auto" tos="">Read More</Link>
                        </div> */}

                        <div className="col-lg-5 align-self-center bg-primary wow fadeIn rounded" data-wow-delay="0.3s" style={{ padding: "3%", height: "50rem" }}>

                            <h1 className="text-white mb-4">We're Best in Bhattacharjee Group with 12 Years of Experience</h1>
                            <p className="text-light mb-4">Welcome to Bhattacharjee Group, where innovation meets excellence across diverse domains for over 12 years.

                                At Bhattacharjee Group, we pride ourselves on being a multifaceted organization operating in four key sectors:
                            </p>
                            <div className="d-flex align-items-center text-white "style={{padding:"10px 0px"}}>
                                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                    <i className="fa fa-check"></i>
                                </div>
                                <span>Construction</span>
                            </div>
                            <div className="d-flex align-items-center text-white "style={{padding:"10px 0px"}}>
                                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                    <i className="fa fa-check"></i>
                                </div>
                                <span>Software Development</span>
                            </div>
                            <div className="d-flex align-items-center text-white "style={{padding:"10px 0px"}}>
                                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                    <i className="fa fa-check"></i>
                                </div>
                                <span>Institution</span>
                            </div>
                            <div className="d-flex align-items-center text-white "style={{padding:"10px 0px"}}>
                                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                    <i className="fa fa-check"></i>
                                </div>
                                <span>Hotels</span>
                            </div>

                        </div>

                        <div className="col-lg-7">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded" >
                                                <div className="service-icon btn-square">
                                                    <i className="fa fa-laptop-code fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">IT</h5>
                                                <p>
                                                    Software development is the creation, testing, and maintenance of computer programs and applications.</p>
                                                <Link className="btn px-3 mt-auto mx-auto" to="/software_development">Read More</Link>
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    <i className="fa fa-user-graduate fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">Institution</h5>
                                                <p>A hub of education and discovery. Here, minds meet to delve into the depths of knowledge, explore new horizons, and innovate for the future. "</p>
                                                <Link to="/institution" className="btn px-3 mt-auto mx-auto" tos="">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-md-4">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    <i className="fas fa-hard-hat fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">Construction</h5>
                                                <p>
                                                    Construction is the process of building structures, such as buildings, bridges, roads, and dams, through the use of materials, tools, and skilled labor.</p>
                                                <Link className="btn px-3 mt-auto mx-auto" to="/construction">Read More</Link>
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    <i className="fa fa-hotel fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">Hotel</h5>
                                                <p>Experience comfort and luxury at our boutique hotel, where impeccable service meets elegant design. </p>
                                                <Link className="btn px-3 mt-auto mx-auto" to="/hotel">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}