
import './App.css';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Header from './component/Header';

import Footer from './component/Footer';
import Home from './component/Home';

import NavContact from './Navbar/NavContact';
import NavAbout from './Navbar/NavAbout';
import NavServices from './Navbar/NavServices';
import NewsLetter from './component/NewsLetter';

import Project from './Navbar/Project';
import Institution from './Pages/Institute/Institution';
import Construction from './Pages/Construction/Construction';
import It from './Pages/It/It';
import Hotel from './Pages/Hotel/Hotel';
import CourseDetails from './Pages/Institute/CourseDetails';
import Web_Development from './Pages/It/Pages/Web_Development';
import Ai_Development from './Pages/It/Pages/Ai_Development';
import Cloud_Solution from './Pages/It/Pages/Cloud_Solution';
import Mobile_App from './Pages/It/Pages/Mobile_App';
import Ui_Ux_Design from './Pages/It/Pages/Ui_Ux_Design';
import Degital_Marketing from './Pages/It/Pages/Degital_Marketing';



function App() {
  return (
    <>
    <Router>
      <Header/>
      <Routes>
      
        <Route path='/' element={<Home/>}/>
        <Route path='/contact' element={<NavContact/>}/>
        <Route path='/about' element={<NavAbout/>}/>
        
        <Route path='/project' element={<Project/>}/>
        <Route path='/services' element={<NavServices/>}/>
        <Route path='/construction' className="dropdown-item" element={<Construction/>}/>
        <Route path='/institution' className="dropdown-item" element={<Institution/>}/>
        <Route path='/software_development' className="dropdown-item" element={<It/>}/>
        <Route path='/hotel' className="dropdown-item" element={<Hotel/>}/>
        <Route path='/course_detail'  element={<CourseDetails/>}/>
        <Route path='/web_development'  element={<Web_Development/>}/>
        <Route path='/ai'  element={<Ai_Development/>}/>
        <Route path='/cloud_solutions'  element={<Cloud_Solution/>}/>
        <Route path='/mobile_app_development'  element={<Mobile_App/>}/>
        <Route path='/ui_ux_design'  element={<Ui_Ux_Design/>}/>
        <Route path='/digital_marketing'  element={<Degital_Marketing/>}/>






       
        
      </Routes>
      {/* <NewsLetter/> */}
      <Footer/>
    </Router>
    </>
  );
}

export default App;
