import React, { useEffect } from 'react'
const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Degital_Marketing() {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">

                                <h1 className="mt-2">Digital Marketing</h1>
                            </div>
                            <p className="mb-4">
                                Digital Marketing is all about creating an online presence and reaching out to your consumer’s screen to sell your products and services. Online marketing has gained a lot of popularity and hence some products don’t even consider advertising through traditional media. Our digital marketing team has successfully completed a large number of projects and has expertise in Google Analytics, Social Media Marketing, and SEO. Connect with us to know more
                                .</p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Search Engine Optimization</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Analytics</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Pay Per Click</h6>

                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Social Media Marketing</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Email Marketing</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Content Marketing</h6>

                                </div>
                            </div>



                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/It/digital-marketing.webp" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Degital_Marketing
